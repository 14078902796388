<!--快检指标设置-按品级-->
<template>
    <div class="quickCheckSettingGrade">
        <div class="header-con">
            <h4>快检指标设置</h4>
        </div>
        <div class="main-con">
            <slider @load-table="handleLoadTable"></slider>
            <RBtableList
                :current-data="'currentData'"
                ref="RBtableList"
            ></RBtableList>
        </div>
    </div>
</template>

<script>
import slider from './slider';
import RBtableList from './RBtableList';
export default {
    components: { slider, RBtableList },
    props: { },
    data() {
        return {
            isGrade: false, // 是否展示国标列表
            currentData: {}, // 当前树节点信息
        };
    },
    watch: {},
    computed: {},
    methods: {
        // 加载表格
        handleLoadTable(data) {
            this.currentData = data;
        },
    },
    created() {},
    mounted() {},
};
</script>
<style lang="stylus" scoped>
.quickCheckSettingGrade
    font-size .16rem
    overflow hidden
    .header-con
        height .4rem
        display flex
        background #fff
        margin-bottom .2rem
        justify-content space-between
        line-height .4rem
        padding 0 .1rem
        .save-btn
            width .6rem
            height .28rem
            text-align center
            background #1577D2
            border-radius .04rem
            color #fff
            margin-top .06rem
            line-height .28rem
            cursor pointer
    .main-con
        height calc(100% - .6rem)
</style>
